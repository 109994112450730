//React
import { Children, useState } from "react";

//Material-UI
import {
  Chip,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  styled,
  IconButton,
  Box
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//Lodash
import { get, noop } from "lodash";



//Date-fns
// import { format } from 'date-fns';

//Local imports
import { memo } from "utils/memo";
import T from "utils/constants/T";
import { PAGINATION } from "utils/constants/pagination";
import { useSelector } from 'store';


//CSS
import './deductionTable.css';
import { formatAmount } from "utils/CommonMethods";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "5px 0px 5px 8px",
  // flexWrap: "wrap"
  // ...theme.typography.subtitle1, 
}));

// const StyledTableRow = styled(TableRow)(() => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: "white",
//   },
//   "&:nth-of-type(even)": {
//     backgroundColor: "white",
//   },
// }));

interface DeductionTableBodyProps {
  page?: number;
  rowsPerPage?: number;
  totalTableRowsCount?: number;
  totalPageCount?: number;
  open?: boolean,
  handleExportViewDeduction?: any,
  deductionViewList?: any[],
  handleClick?: any,
  handleClose?: any,
  // handleViewDeduction?: any,
  handleRunDeduction?: any,
  handleReverseDeduction?: any,
  anchorEl?: any,
  allTableRows: any[]
}
const DeductionTableBody = ({
  page = INITIAL_PAGE,
  rowsPerPage = ROWS_PER_PAGE,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  anchorEl,
  open = false,
  handleExportViewDeduction = noop,
  deductionViewList = [],
  handleClick = noop,
  handleClose = noop,
  // handleViewDeduction = noop,
  handleRunDeduction = noop,
  handleReverseDeduction = noop,
  allTableRows = []
}: DeductionTableBodyProps) => {

  const { selectedCompany } = useSelector((state: any) => state.dashboardSearch);
  const [, setviewDeductions] = useState(false);
  const [statusDeduction, setStatusDeduction] = useState(false);
  const [downloadDeductions, setDownloadDeductions] = useState(false)
  const [viewRunDeductions, setViewRunDeductions] = useState(false)

  const selectedCompanyId = get(selectedCompany, "_id", "");
  const { systemPayrollCode } = useSelector(
    (state: any) => state.companyTabData
  );
  return (

    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const deductionStatus = get(record, 'deduction_status', "");
          const withdrawalStatus = get(record, 'withdrawal_status', "");
          const payPeriodId = get(record, 'pay_period_id', "");
          const viewCompanyId = get(record, "company_id", '');
          const isPreventWithdrawals = get(record, "isPreventWithdrawals", '');
          return (
            <TableRow sx={{ background: "white" }} key={index}>
              {!selectedCompanyId && (
                <StyledTableCell width="200px">{get(record, 'company_name', "")}</StyledTableCell>
              )}
              <StyledTableCell width="200px">
                <Box className="table-column">
                  {get(record, 'pay_periods', "")}
                </Box>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Chip color={deductionStatus === T.UNSENT ? "error" : deductionStatus === T.SENT ? "success" : "info"} label={deductionStatus} size="small" variant="light" />
              </StyledTableCell>
              {systemPayrollCode !== "MYOBADVANCED" &&
                selectedCompanyId &&
                <StyledTableCell>{get(record, 'payPeriodDetails.payPeriodName', "")}</StyledTableCell>
              }
              <StyledTableCell align="center">{withdrawalStatus}</StyledTableCell>
              <StyledTableCell>
                <Box className="table-column">
                  {deductionStatus === T.UNSENT ? 'N/A' : get(record, 'date_time', '')}
                </Box>
              </StyledTableCell>
              <StyledTableCell>{formatAmount(get(record, 'amount', ''))}</StyledTableCell>
              <StyledTableCell>
                <IconButton
                  //  id="basic-button"
                  aria-controls={'basic-menu'}
                  aria-haspopup="true"
                  //  aria-expanded={'true' : undefined}
                  onClick={(e: any) => {
                    handleClick(e, viewCompanyId, payPeriodId, record)

                    // if prevented withdrawls then disabled the view button
                    console.log('isPreventWithdrawals', isPreventWithdrawals)
                    setStatusDeduction(deductionStatus === T.SENT ? true : false)
                    setviewDeductions(isPreventWithdrawals ? true : false)
                    setDownloadDeductions(get(record, 'amount', '') === 0? true : false)
                    setViewRunDeductions(get(record, 'amount', '') === 0? true : false)

                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          );
        })
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleRunDeduction()} disabled={viewRunDeductions} >{statusDeduction ? T.VIEW_DEDUCTION : T.VIEW_RUN_DEDUCTION}</MenuItem>
        <MenuItem onClick={() => handleExportViewDeduction()} disabled={downloadDeductions}>{T.DOWNLOAD_DEDUCTION}</MenuItem>
        {/* <MenuItem onClick={() => handleReverseDeduction()} disabled={!viewDeductions}>{T.REVERSE_DEDUCTION}</MenuItem> */}
      </Menu>

      {allTableRows && allTableRows.length === 0 && (
        <TableRow>
          <StyledTableCell colSpan={10} sx={{ border: "none" }}>
            <Typography variant="body1" textAlign="center">
              {T.NO_RECORDS}
            </Typography>
          </StyledTableCell>
        </TableRow>
      )}
    </TableBody>
  );
};



export default memo(DeductionTableBody);
